<template>
	<form @submit.prevent="$emit('save')" :disabled="disabled">
		<vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
	</form>
</template>

<script>
export default {
	components: {},
	props: {
		value: {
			type: Object,
			default() {
				return {}
			}
		}
	},
	data() {

		return {
			formOptions: {
				validateAfterLoad: true,
				validateAfterChanged: true,
				validateAsync: true
			},
		}
	},
	async mounted() {
	},
	computed: {
		disabled() {
			return !this.canWrite('localization')
		},
		schema() {
			let fields = [
				{
					type: 'input',
					inputType: 'text',
					label: 'Name',
					model: 'Name',
					featured: true,
					required: true,
					disabled: this.disabled
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Code',
					model: 'Code',
					featured: true,
					required: true,
					disabled: this.disabled
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Iso',
					model: 'Iso',
					featured: true,
					required: true,
					disabled: this.disabled
				},
				{
					type: 'switch',
					label: 'Status',
					model: 'Status',
					required: false,
					disabled: this.disabled
				},
				{
					type: "image",
					label: "Img",
					hint: 'svg',
					model: "Img",
					required: false,
					disabled: this.disabled
				},
        {
          type: 'switch',
          label: 'Default',
          model: 'DefaultForTranslation',
          required: false,
          disabled: this.disabled
        },
			]

			return {
				fields
			}
		},
		model: {
			get() {
				return this.value
			},
			set(_val) {
				this.$emit('input', _val)
			}
		}
	}
}
</script>
