<template>
	<div class="mb-5 pb-5">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link :to="{name: 'languages-index'}">Languages</router-link>
				</li>
				<li class="breadcrumb-item active" v-if="model">
					<router-link :to="{name: 'languages-id', params: {id: $route.params.id}}">{{
									model.Name
					}}
					</router-link>
				</li>
			</ol>
		</nav>

		<language-form v-model="model"></language-form>
		<button class="btn btn-primary" @click="save">SAVE</button>
		<button class="btn btn-danger ml-2" @click="remove">DELETE</button>
	</div>
</template>

<script>
import api from '@/api'
import LanguageForm from "../../components/languages/language-form.vue";

export default {
	name: "id",
	components: {LanguageForm},
	data: () => ({
		model: null,
	}),
	async mounted() {
		this.model = await api.Localizations.Languages.get(this.$route.params.id)
	},
	methods: {
		async save() {
			await api.Localizations.Languages.update(this.$route.params.id, this.model)
			alert("Saved")
		},
		async remove() {
			var result = confirm("Are you sure you want to remove this language?");
			if (result) {
				await api.Localizations.Languages.delete(this.$route.params.id, this.model)
				await api.Localizations.LocalizedContents.remove(this.model.Id)
				alert("Deleted")

				this.$router.push({name: 'languages-index'})
			}
		}
	}
}
</script>

<style scoped>

</style>
